import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { iconDocument } from "@wfp/icons";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import PageStandardWithTabs from "../../components/PageStandardWithTabs";
import { getCountryObjectFromCache } from "../../utils";
import MastercardFundationSummaryView from "../WfpManagerHome/components/MastercardFundationSummaryView";

/**
 * The home wfp manager page component.
 */
export const MCFAdminHome = () => {
  const { t } = useTranslation();

  const onClickPrintHome = () => {
    window.print();
  };

  const tabs = [
    {
      id: "MCF Summary",
      label: t("home.manager.tabs.youthInWorkProgramme"),
      Component: MastercardFundationSummaryView,
    },
  ];

  return (
    <PageStandardWithTabs
      title={`${t("home.manager.title")} ${t("common.in")} ${
        getCountryObjectFromCache().name
      }`}
      additional={
        <ButtonsWrapper>
          <Button icon={iconDocument} onClick={onClickPrintHome}>
            {t("common.print")}
          </Button>
        </ButtonsWrapper>
      }
      tabs={tabs}
    />
  );
};

// The component uses the redux store
export default withRouter(MCFAdminHome);
